<template>
  <v-row class="match-height">
    <!-- horizontal -->
    <v-col cols="12" md="12">
      <v-card>
        <v-card-text>
          <v-form class="my-4">
            <v-row>
              <v-col md="2">
                <!-- <input
                  type="date"
                  v-model="filter.tanggal_mulai"
                  style="border: 1px solid grey"
                  @keydown.enter="optionChange()"
                /> -->
                <v-text-field
                  label="Tanggal Awal *"
                  type="date"
                  v-model="filter.tanggal_mulai"
                  outlined
                  hide-details
                  @keydown.enter="optionChange()"
                />
              </v-col>
              <v-col md="1"> Sampai </v-col>
              <v-col md="2">
                <!-- <input
                  type="date"
                  v-model="filter.tanggal_akhir"
                  style="border: 1px solid grey"
                  @keydown.enter="optionChange()"
                /> -->
                <v-text-field
                  label="Tanggal Akhir *"
                  type="date"
                  v-model="filter.tanggal_akhir"
                  outlined
                  hide-details
                  @keydown.enter="optionChange()"
                />
              </v-col>
              <v-col md="4">
                <v-btn color="primary" small @click="optionChange()"> Lihat </v-btn>
                <v-btn color="primary" class="ml-2" small @click="cetak"> Cetak </v-btn>
              </v-col>
            </v-row>
          </v-form>
          <hr />

          <div id="printMe">
            <table cellpadding="0" cellspacing="0" class="header">
              <tr>
                <td style="width: 20%">
                  <img style="width: 150px; height: auto" :src="require('@/assets/images/logos/logo.png')" />
                </td>
                <td style="width: 80%">
                  <center>
                    <span v-if="kop.kop_1 != ''" style="font-size: 12pt; font-weight: bold"
                      >{{ kop.kop_1 }} <br
                    /></span>
                    <span v-if="kop.kop_2 != ''" style="font-size: 12pt; font-weight: bold"
                      >{{ kop.kop_2 }} <br
                    /></span>
                    <span v-if="kop.kop_3 != ''" style="font-size: 10pt">{{ kop.kop_3 }} ><br /></span>
                    <span v-if="kop.kop_4 != ''" style="font-size: 10pt">{{ kop.kop_4 }} ><br /></span>
                  </center>
                </td>
                <td style="width: 20%">&nbsp;</td>
              </tr>
            </table>
            <h3 style="text-align: center">
              Laporan Jurnal Umum Periode
              {{ formatDate(filter.tanggal_mulai) + ' s/d ' + formatDate(filter.tanggal_akhir) }}
            </h3>
            <table cellpadding="0" cellspacing="0" class="body">
              <thead>
                <tr>
                  <th class="text-left">Tanggal</th>
                  <th class="text-left">No Transaksi</th>
                  <th class="text-left">Keterangan</th>
                  <th class="text-left">Akun</th>
                  <th class="text-left">Debit</th>
                  <th class="text-left">Kredit</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in desserts" :key="index">
                  <td>
                    <span v-if="index === 0">
                      {{ item.tanggal }}
                    </span>
                    <span v-else>
                      <span v-if="desserts[index].tanggal === desserts[index - 1].tanggal"></span>
                      <span v-else>{{ item.tanggal }}</span>
                    </span>
                  </td>
                  <td>
                    <span v-if="index === 0">
                      {{ item.id_header }}
                    </span>
                    <span v-else>
                      <span v-if="desserts[index].id_header === desserts[index - 1].id_header"></span>
                      <span v-else>{{ item.id_header }}</span>
                    </span>
                  </td>
                  <td>
                    <span v-if="index === 0">
                      {{ item.keterangan }}
                    </span>
                    <span v-else>
                      <span
                        v-if="
                          desserts[index].id_header === desserts[index - 1].id_header &&
                          desserts[index].keterangan === desserts[index - 1].keterangan
                        "
                      ></span>
                      <span v-else>{{ item.keterangan }}</span>
                    </span>
                  </td>

                  <td>{{ item.nomor + ' ' + item.nama }}</td>
                  <td style="text-align: right">{{ new Intl.NumberFormat(['id']).format(item.debit) }}</td>
                  <td style="text-align: right">{{ new Intl.NumberFormat(['id']).format(item.kredit) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import '@/styles/print.css'

import axios from 'axios'

const apiRoot = process.env.VUE_APP_APIURL
export default {
  data: () => ({
    desserts: [],
    loading: true,
    filter: {
      // tanggal_mulai: new Date().toJSON().slice(0, 8).replace(/-/g, '-') + '01',
      tanggal_mulai: new Date().toJSON().slice(0, 10).replace(/-/g, '-'),
      tanggal_akhir: new Date().toJSON().slice(0, 10).replace(/-/g, '-'),
    },
    kop: {},
  }),

  computed: {
    token() {
      return this.$store.getters.token
    },
  },

  watch: {},

  created() {
    this.getDataFromApi()
    this.getParameterKop()
  },

  methods: {
    async cetak() {
      // Pass the element id here
      await this.$htmlToPaper('printMe', {})
    },
    getParameterKop() {
      this.$store.dispatch('loading', true)

      const headers = {
        Authorization: this.token,
      }

      this.$store.dispatch('loading', true)
      axios
        .get(`${apiRoot}/api/Dashboard/getParameterKop`, { headers })
        .then(response => {
          if (response.data.code === 401) {
            this.$store.dispatch('logout', 'Session berakhir!')
          } else if (response.data.code === 200) {
            this.kop = response.data.data.parameter
          } else {
            alert(response.data.message)
          }
          this.$store.dispatch('loading', false)
        })
        .catch(error => {
          alert(error)
          this.$store.dispatch('loading', false)
        })
    },
    getDataFromApi() {
      this.$store.dispatch('loading', true)

      const headers = {
        Authorization: this.token,
      }
      const form = new FormData()
      form.append('tanggal_mulai', this.filter.tanggal_mulai)
      form.append('tanggal_akhir', this.filter.tanggal_akhir)
      axios
        .post(`${apiRoot}/api/Laporan/jurnal_umum`, form, { headers })
        .then(response => {
          if (response.data.code === 401) {
            this.$store.dispatch('logout', 'Session berakhir!')
          } else if (response.data.code === 200) {
            this.desserts = response.data.data
          } else {
            alert(response.data.message)
          }
          this.$store.dispatch('loading', false)
        })
        .catch(error => {
          alert(error)
          this.$store.dispatch('loading', false)
        })
    },
    optionChange() {
      if (this.filter.tanggal_mulai != '' && this.filter.tanggal_akhir != '') {
        this.getDataFromApi()
      }
    },
  },
}
</script>
